import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import './index.css';
import App from './App';
import store from "./redux";
import reportWebVitals from './reportWebVitals';
// import OneSignal from 'react-onesignal';
// import { environment } from './api/baseUrl';

let persistor = persistStore(store);

// const initializeOneSignal = () => {
//   if (environment === 'production') {
//     OneSignal.init({ 
//       appId: '0114ab34-631e-4d25-9fc9-74c7de82ea76',
//       safari_web_id: "web.onesignal.auto.017d7a1b-f1ef-4fce-a00c-21a546b5491d",
//       allowedNodeEnvironmentFlags: true,
//       serviceWorkerPath: 'OneSignalSDKWorker.js',
//       serviceWorkerUpdaterPath: 'OneSignalSDKUpdaterWorker.js'
//     });
//   } else if(environment === 'staging') {
//     OneSignal.init({ 
//       appId: "ab9b6b64-e9ec-4489-9805-b43bfc7211cd",
//       safari_web_id: "web.onesignal.auto.48d27e8c-5bf0-4f8f-a083-e09c208eb2cb",
//       serviceWorkerPath: 'OneSignalSDKWorker.js',
//       serviceWorkerUpdaterPath: 'OneSignalSDKUpdaterWorker.js'
//     });
//   }
// };

// initializeOneSignal();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
