// reducers/sseReducer.js
import { LOGOUT_USER } from "../types/authTypes";
import { GET_CHALLENGE_PROGRESS } from "../types/challengeTypes";

const initialState = {
  progress: []
};

const sseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHALLENGE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };

    case LOGOUT_USER:
      return initialState;

    default:
      return state;
  }
};

export default sseReducer;
