import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "./redux/actions/authAction";
import "react-toastify/dist/ReactToastify.min.css";
import Routes from "./config/routes";
import "./styles/main.css";
import "./App.scss";
import { useMediaQuery } from 'react-responsive';
import rotate_device from '../src/assets/rotate-device.png';
import Bowser from 'bowser';

function App() {
  const dispatch = useDispatch();
  const logout = () => {
    signOut()(dispatch);
  };

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
    "wheel",
    "touchstart",
    "touchmove", 
    "touchend",
  ];

  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      logout();
    }, 18000000); // 5 hours timeout
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
    handleLogoutTimer();
  };

  useEffect(() => {
    const handleUserActivity = () => {
      resetTimer();
    };

    handleLogoutTimer();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isMobile = () => /Mobi|Android|iPhone|iPod/.test(navigator.userAgent) || browser.satisfies({ mobile: true });
  
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (isMobile() && isLandscape) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [isLandscape]);

  return (
    <div className="App">
      <div className={`rotate-device-message ${showMessage ? 'show' : ''}`}>
        <img src={rotate_device} height={100} width={100} alt="Rotate Device Icon" />
        <p className="mt-4">Please rotate your Device</p>
      </div>
      {!showMessage && <Routes />}
    </div>
  );
}

export default App;
