import PropTypes from "prop-types";
import React, { Component } from "react";
import Icon from "./Icons";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
            countdown: 5, // Add countdown state
        };
        this.redirectTimeout = null;
        this.countdownInterval = null; // Add countdown interval
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render shows the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error to an error reporting service here
        console.error("ErrorBoundary caught an error", error, errorInfo);
        this.setState({ errorInfo });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.hasError && !prevState.hasError && !this.redirectTimeout) {
            this.redirectTimeout = setTimeout(() => {
                window.location.replace("/");
            }, 5000); // Redirect after 5 seconds

            this.countdownInterval = setInterval(() => {
                this.setState(prevState => ({
                    countdown: prevState.countdown - 1
                }));
            }, 1000); // Update countdown every second
        }
    }

    componentWillUnmount() {
        if (this.redirectTimeout) {
            clearTimeout(this.redirectTimeout);
        }
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
    }

    handleRetry = () => {
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ padding: 0, fontFamily: "Arial, sans-serif" }}>
                    <section className="w-full m-a justify-center flex mt-8">
                        <Icon id="mookie-icon" width="132px" height="32px" />
                    </section>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <main
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "50px",
                            }}
                        >
                            <section
                                style={{
                                    maxWidth: "300px",
                                    width: "100%",
                                    padding: "0 20px",
                                }}
                            >
                                <section style={{ textAlign: "center" }}>
                                    <h2
                                        style={{
                                            fontSize: "21px",
                                            marginBottom: "20px",
                                            color: "#333",
                                        }}
                                    >
                                        Something went wrong{" "}
                                        <span role="img" aria-label="broken">
                                            💔
                                        </span>
                                    </h2>
                                    <p
                                        style={{
                                            fontSize: "16px",
                                            color: "#888",
                                            lineHeight: "22px",
                                        }}
                                    >
                                        Please refresh this page or check your internet connection.
                                    </p>
                                    <div style={{ marginTop: "20px" }}>
                                        <button
                                            style={{
                                                padding: "10px 20px",
                                                fontSize: "16px",
                                                color: "#fff",
                                                backgroundColor: "#C734E5",
                                                border: "none",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                marginRight: "10px",
                                            }}
                                            onClick={this.handleRetry}
                                        >
                                            Reload
                                        </button>
                                        <button
                                            style={{
                                                padding: "10px 20px",
                                                fontSize: "16px",
                                                color: "#fff",
                                                backgroundColor: "#555",
                                                border: "none",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => window.location.replace("/")}
                                        >
                                            Go Home
                                        </button>
                                    </div>
                                    <p
                                        style={{
                                            fontSize: "12px",
                                            color: "#aaa",
                                            marginTop: "10px",
                                        }}
                                    >
                                        You will be redirected in {this.state.countdown} seconds.
                                    </p>
                                </section>
                            </section>
                        </main>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
